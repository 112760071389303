import { Request } from 'express'
import { NextRequest } from './nextJS'

const isExpressRequest = (request: NextRequest): request is Request => {
    return 'header' in request
}

export const getHeaderValue = (request: NextRequest | undefined, header: string) => {
    if (!request) {
        return undefined
    }
    if (isExpressRequest(request)) {
        return request.header(header)
    }
    const lowerCaseHeader = header.toLowerCase()
    const foundHeaderHeader = Object.keys(request.headers).find((h) => h?.toLowerCase() === lowerCaseHeader)
    return (request.headers as { [key: string]: string | undefined })[foundHeaderHeader ?? '']
}
