import { AutoMotorAdDetailUserZoom } from '@bbx/common/components/UserZoom/UserZoom'
import { formatAdvertDetails } from '@bbx/common/lib/formatAdvertDetails'
import { getSellerType } from '@bbx/common/lib/getSellerType'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { fetchFromContextLink } from '@bbx/search-journey/common/api/searchApiClient'
import { SeoSearchTerms } from '@bbx/search-journey/common/SeoSearchTerms'
import { getAdDetails, getAdSimilarAdsOrCategory } from '@bbx/search-journey/sub-domains/ad-detail/api/addetailApiClient'
import { AdIdNotFoundAlert } from '@bbx/search-journey/sub-domains/ad-detail/components/common/AdIdNotFoundAlert/AdIdNotFoundAlert'
import { AutoMotorAdDetailContainer } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/auto-motor/AutoMotorAdDetailContainer'
import { AutoJsonLd } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/auto-motor/JsonLD/AutoJsonLd'
import { LeaderboardAndSkyscraperLayout } from '@wh/common/chapter/components/Layouts/LeaderboardAndSkyscraperLayout'
import { SeoMetaTagsForSeoMetaData } from '@wh/common/chapter/components/SeoMetaTags/SeoMetaTags'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { callPageView } from '@wh/common/chapter/lib/tagging/tagging'
import { adTypeIdMap } from '@wh/common/chapter/types/AdType'
import { OptionalPageOptions } from '@wh/common/chapter/types/nextJS'
import { VerticalId, verticalIdMap } from '@wh/common/chapter/types/verticals'
import { GetServerSidePropsContext, GetServerSidePropsResult, NextPage } from 'next'
import React, { Fragment, FunctionComponent, useEffect } from 'react'
import { isUserLoggedIn, useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { type ServerResponse } from 'http'
import { HereMapContext } from '@bbx/search-journey/common/components/Map/HereMapContext'

type ErrorProps = {
    is404: true
}

type AdDetailProps = {
    advertDetails: AdvertDetails
    seoSearchTerms: SeoSearchTerms[] | null
    hereApiKey: string
}

const AutoMotorAdDetail: NextPage<AdDetailProps | ErrorProps> & OptionalPageOptions = (props) => {
    useEffect(() => {
        if ('isNotAvailable' in props || 'is404' in props) {
            return
        }
    }, [props])

    const [profileData] = useProfileData()

    if ('isNotAvailable' in props) {
        return null
    }

    if ('is404' in props) {
        return <AdIdNotFoundAlert />
    }

    const { advertDetails, seoSearchTerms, hereApiKey } = props

    return (
        <Fragment>
            <AdDetailTagger advertDetails={advertDetails} />

            <SeoMetaTagsForSeoMetaData
                seoMetaData={advertDetails.seoMetaData}
                ogSiteImageUrl={advertDetails.advertImageList.advertImage[0]?.referenceImageUrl}
            />
            <AutoMotorAdDetailUserZoom />
            <AutoJsonLd advertDetails={advertDetails} />
            <HereMapContext.Provider value={{ apiKey: hereApiKey }}>
                <AutoMotorAdDetailContainer
                    advertDetails={formatAdvertDetails(advertDetails)}
                    sellerType={getSellerType(advertDetails.attributes)}
                    profileData={isUserLoggedIn(profileData) ? profileData : undefined}
                    key={advertDetails.id}
                    seoSearchTerms={seoSearchTerms ?? []}
                />
            </HereMapContext.Provider>
        </Fragment>
    )
}

export const adDetailGetServerSideProps =
    (verticalId: VerticalId, isNcPage: boolean = false) =>
    async (context: GetServerSidePropsContext): Promise<GetServerSidePropsResult<AdDetailProps | ErrorProps>> => {
        const { query, req, res } = context

        const adId = adIdFromPathParameters(query.seopath as string[])

        if (!adId) {
            return { props: { is404: true } }
        }

        try {
            const advertDetails = await getAdDetails(adId, req)

            const seoBoxContextLink = findContextLinkWithId('seoLinkBoxLink', advertDetails.contextLinkList)
            const seoSearchTerms = seoBoxContextLink ? await fetchFromContextLink<SeoSearchTerms[]>(seoBoxContextLink) : undefined

            if (verticalId !== advertDetails.verticalId || redirectBetweenRealEstateAndNc(advertDetails, isNcPage)) {
                return { redirect: { destination: `/iad/object?adId=${adId}`, permanent: true } }
            }

            return {
                props: {
                    advertDetails,
                    seoSearchTerms: seoSearchTerms || null,
                    hereApiKey: process.env.HERE_MAPS_RENDERING_API_KEY!,
                },
            }
        } catch (error) {
            const apiError = wrapInApiErrorIfNecessary(error)

            if (apiError.statusCode && apiError.statusCode >= 400 && apiError.statusCode <= 499) {
                return await redirectToSimilarCategory(adId, res)
            }

            throw error
        }
    }

const redirectBetweenRealEstateAndNc = (advertDetails: AdvertDetails, isNcPage: boolean) => {
    return (
        (advertDetails.adTypeId === adTypeIdMap.ESTATE_NEW_CONSTRUCTION && !isNcPage) ||
        (advertDetails.adTypeId !== adTypeIdMap.ESTATE_NEW_CONSTRUCTION && isNcPage)
    )
}

const adIdPathSegmentRegex = /^.*-(\d+)$/

export const adIdFromPathParameters = (seopath: string | string[]): number | undefined => {
    const pathSegments = Array.isArray(seopath) ? seopath : [seopath]
    const lastMatchingSegment = pathSegments.reverse().find((segment) => adIdPathSegmentRegex.test(segment))
    if (!lastMatchingSegment) {
        return undefined
    }
    const matchResult = adIdPathSegmentRegex.exec(lastMatchingSegment)
    const adIdString = matchResult?.[1]
    if (!adIdString) {
        return undefined
    }
    const adIdNumber = parseInt(adIdString, 10)
    return isNaN(adIdNumber) ? undefined : adIdNumber
}

const redirectToSimilarCategory = async (adId: number, res: ServerResponse): Promise<GetServerSidePropsResult<ErrorProps>> => {
    try {
        const contextLink = await getAdSimilarAdsOrCategory(adId)

        if (contextLink) {
            res?.setHeader('Cache-Control', 'no-cache')
            return { redirect: { destination: `${contextLink.uri}?fromExpiredAdId=${adId}`, permanent: true } }
        }
    } catch (_error) {}

    return { props: { is404: true } }
}

const AdDetailTagger: FunctionComponent<{ advertDetails: AdvertDetails }> = ({ advertDetails }) => {
    useEffect(() => {
        callPageView('adview', { taggingData: advertDetails.taggingData })
    }, [advertDetails, advertDetails.id])
    return null
}

export const getServerSideProps = adDetailGetServerSideProps(verticalIdMap.MOTOR)
AutoMotorAdDetail.Layout = LeaderboardAndSkyscraperLayout
AutoMotorAdDetail.allowAndroidBanner = false

export default AutoMotorAdDetail
